/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        //*Mobile Menu*//
        $( ".c-header__toggle" ).click(function(e) {
          e.preventDefault();

          $('.c-mobile__menu').animate({height: 'toggle'});
        });

        // for the mobile animation

        $(".c-header__toggle").click(function(){
          $(this).toggleClass("is-active");
        });


        $('.reveal').click(function(){
          $(this).nextAll().slideToggle();
        });


        //   Intro Image
        var colshero = $('.i-img').data('desktop'),
        colmshero = $('.i-img').data('mobile');
        if(viewportWidth < 500 ){
          $('.i-img').css('background-image', 'url('+colmshero+')');
        }else{
          $('.i-img').css('background-image', 'url('+colshero+')');
        }

        /// Mobile Sub Menu Anchor
        $(function () {
          var children = $('.c-nav__mobile > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
          $('<span class="drop-menu"><i class="fas fa-angle-down"></i></span>').insertAfter(children);
          $('.c-nav__mobile .drop-menu').click(function (e) {
            $(this).next().slideToggle(300);
            return false;
          });
        });

        //   Testimonial Image
        var colstestimonial = $('.section--testimonials').data('desktop'),
        colsmtestimonial = $('.section--testimonials').data('mobile');
        if(viewportWidth < 500 ){
          $('.section--testimonials').css('background-image', 'url('+colsmtestimonial+')');
        }else{
          $('.section--testimonials').css('background-image', 'url('+colstestimonial+')');
        }


        //   New Patient
        var colspatient = $('.p-img').data('desktop'),
        colsmpatient = $('.p-img').data('mobile');
        if(viewportWidth < 500 ){
          $('.p-img').css('background-image', 'url('+colsmpatient+')');
        }else{
          $('.p-img').css('background-image', 'url('+colspatient+')');
        }


        //  Request Appointment
        var col_appointment = $('.section--appointment').data('desktop'),
        col_m_appointment = $('.section--appointment').data('mobile');
        if(viewportWidth < 500 ){
          $('.section--appointment').css('background-image', 'url('+col_m_appointment+')');
        }else{
          $('.section--appointment').css('background-image', 'url('+col_appointment+')');
        }






      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          // tasks to do if it is a Mobile Device
          $(function () {
            var children = $('.p-list > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
            $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
            $('.p-list .drop-menu').click(function (e) {
              $(this).next().slideToggle(300);
              return false;
            });
          });

        }

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
